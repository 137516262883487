<template>
  <div>
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->

    <app-dialog v-model="dialog" :title="mjo_header.document_no_" :loading="loading" class="dialogdetail" max-width="1400">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()"></action-button>
        <v-spacer></v-spacer>
        <app-document-status v-model="mjo_header.status" class="ml-1"></app-document-status>
        <app-document-status v-model="mjo_header.substatus" class="ml-1"></app-document-status>
      </template>

      <v-row>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <card-expansion title="General">
                <!-- <v-sheet elevation="1" class="pa-0"> -->
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field label="Document No." v-model="mjo_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Date" v-model="mjo_header.document_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Type" v-model="mjo_header.document_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Company ID" v-model="mjo_header.company_id" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Area Code" v-model="mjo_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Department" v-model="mjo_header.dept_name" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Tipe Pekerjaan" v-model="mjo_header.job_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Created By" v-model="mjo_header.created_by_username" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Created Date" v-model="mjo_header.created_date" hide-details outlined dense class="mb-2" readonly type="datetime-local"></v-text-field>
                    <v-text-field label="Status" v-model="mjo_header.status" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Sub Status" v-model="mjo_header.substatus" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Prioritas" v-model="mjo_header.priority" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Tanggal Rusak" v-model="mjo_header.damage_at" hide-details outlined dense class="mb-2" readonly type="datetime-local"></v-text-field>
                  </v-col>
                </v-row>
                <v-btn color="light" elevation="1" v-if="!loading" @click="redirect('Administration.MJO.Detail', { id: id })">View Detail</v-btn>
                <!-- </v-sheet> -->
              </card-expansion>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <card-expansion :hide-title="true">
                <template #title>
                  <v-tabs v-model="tab2">
                    <v-tab>Fixed Asset</v-tab>
                    <v-tab>Approval</v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-model="tab2">
                  <v-tab-item class="pa-1">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field label="FA No." v-model="mjo_header.fa_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Company ID" v-model="mjo_header.fa_company_id" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Description" v-model="mjo_header.fa_description" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Description 2" v-model="mjo_header.fa_description2" hide-details outlined dense class="mb-2" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field label="Area Code" v-model="mjo_header.fa_area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Location Code" v-model="mjo_header.fa_location_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Responsibility" v-model="mjo_header.fa_responsibility" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Serial No." v-model="mjo_header.fa_serial_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="pa-1">
                    <ApprovalList v-bind:data-list="mjo_approval" elevation="1"></ApprovalList>
                  </v-tab-item>
                </v-tabs-items>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <app-comment :document_id="parseInt(mjo_header.id)" :source_document="mjo_header.source_document" :document_no_="mjo_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-dialog>


  </div>
</template>

<script>
// import CommentList from "./CommentList";
import ApprovalList from "./ApprovalList.vue";
import ActionButton from './ActionButton.vue';

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // CommentList,
    ApprovalList,
    ActionButton,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      tab2: null,
      data_detail: {},
      mjo_header: {},
      mjo_approval: [],
      mjo_comment: [],
      mjo_pic: [],
    };
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
  },

  computed: {
  },

  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    showDialog() {
      this.dialog = true;
      this.getDetail(true);
    },

    async getDetail(refresh = false) {
      this.loading = true;

      this.mjo_header = {};
      this.mjo_approval = [];
      this.mjo_comment = [];
      this.user = {};

      if (refresh == true) this.AxiosStorageRemove("GET", "mjo/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("mjo/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.mjo_header = res.data.data.mjo_header;
          this.mjo_approval = res.data.data.mjo_approval;
          this.mjo_comment = res.data.data.mjo_comment;
          this.mjo_pic = res.data.data.mjo_pic;
          this.user = res.data.data.user;

          console.log(this.data_detail);


          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  }
};
</script>

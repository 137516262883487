var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","elevation":"0"}},[_c('v-card-title',[_vm._v("General Affair")]),_c('v-divider'),_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{staticStyle:{"max-width":"100px"}},[_c('v-select',{staticStyle:{"max-width":"100px"},attrs:{"items":_vm.itemsPerPageOption,"label":"Show Items","dense":"","hide-details":"","single-line":""},model:{value:(_vm.datatable_options.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.datatable_options, "itemsPerPage", $$v)},expression:"datatable_options.itemsPerPage"}})],1),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"dense":"","autofocus":"","outlined":"","rounded":"","placeholder":"Search","append-icon":"mdi-magnify","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":"","title":"Filter"},on:{"click":function($event){return _vm.showFilter()}}},[_c('v-icon',[_vm._v("mdi-filter")])],1),_c('v-btn',{attrs:{"icon":"","title":"Refresh"},on:{"click":function($event){return _vm.getData(true)}}},[_c('v-icon',[_vm._v("mdi-sync")])],1)],1),_c('v-data-table',{staticClass:"mb-3 my-0",attrs:{"options":_vm.datatable_options,"loading":_vm.datatable.loading,"loading-text":_vm.datatable.loadingText,"headers":_vm.datatable.columns,"items":_vm.datatable.data,"search":_vm.search,"hide-default-header":_vm.isMobile,"server-items-length":_vm.datatable.totalRows,"items-per-page":_vm.datatable_options.itemsPerPage,"dense":"","hide-default-footer":""},on:{"update:options":function($event){_vm.datatable_options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.document_no_",fn:function(ref){
var item = ref.item;
return [_c('detail-dialog',{attrs:{"id":parseInt(item.id)},on:{"callback":function($event){return _vm.getData(true)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":on}},[_vm._v(_vm._s(item.document_no_))])]}}],null,true)})]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [(item.received)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('app-document-status',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.substatus",fn:function(ref){
var item = ref.item;
return [_c('app-document-status',{model:{value:(item.substatus),callback:function ($$v) {_vm.$set(item, "substatus", $$v)},expression:"item.substatus"}})]}}],null,true)},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("Your search for \""+_vm._s(_vm.search)+"\" found no results.")])],1),_c('div',{staticClass:"text-right pt-2 mx-auto",staticStyle:{"max-width":"600px"}},[_c('v-pagination',{attrs:{"length":_vm.totalPage},model:{value:(_vm.datatable_options.page),callback:function ($$v) {_vm.$set(_vm.datatable_options, "page", $$v)},expression:"datatable_options.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-icon v-bind="$attrs" v-on="$listeners" :color="iconColor" @click="showDialog()">
      {{ iconText }}
    </v-icon>

    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>Info</v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item style="min-height: 20px !important;">
              <v-list-item-content class="py-0 font-weight-bold">Received By:</v-list-item-content>
              <v-list-item-content class="py-0 align-end">{{ receiveBy }}</v-list-item-content>
            </v-list-item>
            <v-list-item style="min-height: 20px !important;">
              <v-list-item-content class="py-0 font-weight-bold">Received Date:</v-list-item-content>
              <v-list-item-content class="py-0 align-end">{{ receiveDate }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    value: {
      type: Boolean,
      default: false
    },
    receiveBy: {
      type: String,
      default: ""
    },
    receiveDate: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      internalValue: this.value,
      dialog: false,
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.internalValue = val
      } else {
        this.internalValue = false
      }
    },
    internalValue(val) {
      this.$emit('input', val);
    },
  },
  computed: {
    iconColor() {
      if (this.internalValue == false) {
        return "grey lighten-2";
      }
      return "success";
    },
    iconText() {
      if (this.internalValue == true) {
        return 'mdi-file-document-check';
      }
      return 'mdi-file-document-minus';
    }
  },
  methods: {
    showDialog() {
      if (this.internalValue == true) {
        this.dialog = true
      }
    }
  }
}
</script>
<template>
  <div>
    <v-card flat elevation="0">
      <v-card-title>General Affair</v-card-title>
      <v-divider></v-divider>
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-text-field dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="showFilter()" title="Filter">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
        <template v-slot:[`item.document_no_`]="{ item }">
          <detail-dialog :id="parseInt(item.id)" @callback="getData(true)">

            <template v-slot:activator="{ on }">
              <a href="javascript:;" v-on:click="on">{{ item.document_no_ }}</a>
            </template>

          </detail-dialog>
        </template>
        <template v-slot:[`item.received`]="{ item }">
          <v-icon v-if="item.received" color="green">mdi-check</v-icon>
          <v-icon v-else color="grey">mdi-minus</v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <app-document-status v-model="item.status"></app-document-status>
        </template>
        <template v-slot:[`item.substatus`]="{ item }">
          <app-document-status v-model="item.substatus"></app-document-status>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>
      </v-data-table>

      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import DetailDialog from "./DetailDialog.vue";
export default {
  components: {
    DetailDialog
  },
  data() {
    return {
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20
      },
      itemsPerPageOption: [20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "No.", value: "document_no_", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Source Document", value: "source_document", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap", cellClass: "text-no-wrap text-center" },
          { text: "Received", value: "received", class: "text-no-wrap", cellClass: "text-no-wrap text-center" },
          { text: "Sub Status", value: "substatus", class: "text-no-wrap", cellClass: "text-no-wrap text-center" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "FA Serial No.", value: "fa_serial_no_", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "FA Description", value: "fa_description", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Problem Description", value: "problem_description", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap", cellClass: "text-no-wrap" },
        ],
        data: [],
      },

      dialogFilter: false,
      filterData: {
        status: [
          'open',
          'waiting-approval',
          'approved',
          'cancel',
          'closed',
        ],
        substatus: [
          'on-process',
          'verified',
        ],
        area_code: [],
        department: [],
      },
      filterForm: {
        status: [],
        substatus: [],
        area_code: [],
        department: [],
      }
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      const uri = "mjo/data-ga";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;
      this.datatable.loading = true;
      const params = {
        search: this.search,
        sort_by: sortBy,
        sort_desc: sortDesc,
        page: page,
        limit: itemsPerPage,
        filter: this.filterForm
      }
      this.$axios
        .get(uri, {
          sessionStorage: !refresh,
          params: params
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    showFilter() {
      this.dialogFilter = true;
      this.filterData.area_code = this.$store.state.master.area;
    },
    closeFilter() {
      this.dialogFilter = false;
    },
    runFilter() {
      this.dialogFilter = false;

      this.getData(true);
    },
    resetFilter() {
      this.filterForm = {
        status: [],
        substatus: [],
        area_code: [],
        department: [],
      }
      this.dialogFilter = false;
      this.getData(true);
    }
  },

  mounted() {
    this.getData();
  },
};
</script>
